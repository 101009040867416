import React from "react";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlay } from "@fortawesome/free-solid-svg-icons";

export default function TailCard(props) {
	const { tHeader, tSubtitle, linkTo } = props;

	function redirectToPassedUrl() {
		window.location.href = linkTo;
	}

	return (
		<div
			onClick={redirectToPassedUrl}
			className="lg:w-80 md:w-4 h-20 bg-gradient-to-r via-blue-400 text-lg from-pink-500 to-indigo-500 text-white mt-4 p-4 transition-all duration-200 hover:shadow-xl border-pink-600 border-l-2"
		>
			<h1 className="font-bold">{tHeader}</h1>
			<p className="text-sm">{tSubtitle}</p>

			{/* <div className="ml-auto flex justify-center items-center bg-white rounded-full -my-10 w-10 h-10">
				<FontAwesomeIcon icon={faPlay} color="#D64550" />
			</div> */}
		</div>
	);
}
