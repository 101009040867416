import React from "react";
import "./App.css";
import TailCard from "./Components/TailCard";

function App() {
	return (
		<div className="grid w-screen h-screen m-4 lg:grid-cols-5 md:grid-cols-1">
			<TailCard tHeader="Angels" tSubtitle="18 Steps of Father Brahma" linkTo="angels" />
			<TailCard tHeader="Christmas" tSubtitle="Christmas blessings" linkTo="christmas" />
			<TailCard tHeader="Jewels of Light" tSubtitle="You are a Jewel" linkTo="JewelsOfLight" />
			<TailCard tHeader="The Letter" tSubtitle="from Brahma, to the children" linkTo="letter" />
			<TailCard tHeader="Mahavakya" tSubtitle="Golden Teachings of God" linkTo="mahavakya" />
			<TailCard tHeader="Avyakt Murli" tSubtitle="Today's Avyakt Murli For You" linkTo="murli" />
			<TailCard tHeader="The Pilgrimage of Love" tSubtitle="Pilgrimage of Remembrance" linkTo="pilgrimage" />
			<TailCard tHeader="Swamans" tSubtitle="Points of Self Respect" linkTo="Swamans" />
		</div>
	);
}

export default App;
